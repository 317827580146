<template>
  <div>
    <withdrawal-request-sidebar />
    <b-row class="match-height">
      <b-col
        md="4"
        v-if="withdrawals && withdrawals.pending_request_amount > 0"
      >
        <b-card no-body>
          <b-card-body class="pb-0">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <b-avatar :variant="`light-warning`" size="45">
                <img
                  class="feather"
                  src="~@/assets/images/svg/cash-clock.svg"
                  width="30px"
                />
              </b-avatar>
              <div>
                <feather-icon
                  v-if="eyeOpenRequest"
                  icon="EyeIcon"
                  class="mouse-pointer"
                  size="20"
                  stroke-width="3"
                  @click="toSeeRequest()"
                />
                <feather-icon
                  v-if="!eyeOpenRequest"
                  icon="EyeOffIcon"
                  class="mouse-pointer"
                  size="20"
                  stroke-width="3"
                  @click="toSeeRequest()"
                />
              </div>
            </div>

            <div class="truncate">
              <span>Solicitação de saque realizada</span>
              <div class="position-relative w-75 h-auto">
                <div
                  :class="[
                    eyeOpenRequest? '' : 'blur-effect',
                    'font-weight-bolder', 
                    'mt-2'
                  ]"
                  @click="toSeeRequest"
                >
                  <h2 class="mb-25 font-weight-bolder">
                    {{ withdrawals.pending_request_amount | toCurrency }}
                  </h2>
                </div>
              </div>
            </div>
          </b-card-body>
          <b-button class="m-2" variant="danger" @click="deleteRequest">
            Remover solicitação
          </b-button>
          <div class="mb-2"></div>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-card no-body>
          <b-card-body class="pb-0">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <b-avatar :variant="`light-success`" size="45">
                <img
                  class="feather"
                  src="~@/assets/images/svg/cash.svg"
                  width="30px"
                />
              </b-avatar>
              <div>
                <feather-icon
                  v-if="eyeOpenBalance"
                  icon="EyeIcon"
                  class="mouse-pointer"
                  size="20"
                  stroke-width="3"
                  @click="toSeeBalance()"
                />
                <feather-icon
                  v-if="!eyeOpenBalance"
                  icon="EyeOffIcon"
                  class="mouse-pointer"
                  size="20"
                  stroke-width="3"
                  @click="toSeeBalance()"
                />
              </div>
            </div>

            <div class="truncate">
              <span>Saldo</span>
              <div class="position-relative w-75 h-auto">
                <div
                  :class="[
                    eyeOpenBalance? '' : 'blur-effect',
                    'font-weight-bolder', 
                    'mt-2'
                  ]"
                  @click="toSeeBalance"
                >
                  <h2 class="mb-25 font-weight-bolder">
                    {{ withdrawals ? withdrawals.balance : 0 | toCurrency }}
                  </h2>
                </div>
              </div>
            </div>
          </b-card-body>
          <b-button
            v-if="
              withdrawals &&
              withdrawals.balance > 0 &&
              withdrawals.pending_request_amount == 0
            "
            class="m-2"
            variant="primary"
            @click="createRequest"
          >
            Solicitar Saque
          </b-button>
          <div class="mb-2"></div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BPopover,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { mapActions, mapGetters } from "vuex";
import WithdrawalRequestSidebar from "@/modules/withdrawal/components/WithdrawalRequestSidebar";
import * as commissionPeriods from "@/constants/commission_periods";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    VueApexCharts,
    BPopover,
    BButton,
    WithdrawalRequestSidebar,
  },
  props: {
    updateTable: Function,
    toSeeBalance: Function,
    eyeOpenBalance: Boolean,
  },
  data() {
    return {
      // eyeOpenBalance: false,
      eyeOpenRequest: false,
    };
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  computed: {
    ...mapGetters({
      withdrawalSummary: sharedTypes.WITHDRAWAL_SUMMARY,
    }),
    withdrawals: function () {
      return this.withdrawalSummary;
    },
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    ...mapActions({
      getWithdrawalSummary: sharedTypes.GET_WITHDRAWAL_SUMMARY,
      openWithdrawalRequestSidebar: types.OPEN_WITHDRAWAL_REQUEST_SIDEBAR,
      deleteWithdrawal: types.DELETE_WITHDRAWAL,
    }),
    // toSeeBalance() {
    //   this.eyeOpenBalance = !this.eyeOpenBalance;
    // },
    toSeeRequest() {
      this.eyeOpenRequest = !this.eyeOpenRequest;
    },
    getSummary() {
      this.getWithdrawalSummary().catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar o saldo para saque. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    createRequest() {
      this.openWithdrawalRequestSidebar({
        saveAction: () => {
          this.getSummary();
          this.updateTable();
        },
      });
    },
    deleteRequest() {
      this.$swal({
        title: "Tem certeza?",
        text: `Você removerá a solicitação de saque.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteWithdrawal()
            .then((response) => {
              this.getSummary();
              this.updateTable();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `A solicitação de saque foi removida com sucesso!`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover a solicitação de saque. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.commission-totalizer-title {
  font-weight: 500;
  font-size: 1.2rem;
}

.mouse-pointer {
  cursor: pointer;
}

.blur-effect {
  filter: blur(5px);
}

</style>