<template>
  <div class="position-relative">
    <withdrawal-request-card
      :updateTable="search"
      :toSeeBalance="toSeeBalance"
      :eyeOpenBalance="eyeOpenBalance"
    />
    <withdrawal-details-sidebar />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontradas solicitações de saques para esta busca"
        :busy="loading"
      >
        <!-- Column: Data da solicitação -->
        <template #cell(requested_at)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.requested_at
            }}</span>
          </div>
        </template>
        <!-- Column: Valor solicitado -->
        <template #cell(amount)="data">
          <div class="position-relative w-auto h-auto">
            <div
              :class="[
                eyeOpenBalance ? '' : 'blur-effect',
                'font-weight-bolder',
                'my-1',
              ]"
              @click="toSeeBalance"
            >
              <div class="text-wrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.amount | toCurrency
                }}</span>
              </div>
            </div>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status_name)="data">
          <div class="text-wrap">
            <b-badge
              pill
              variant="withdrawal-pending"
              v-if="data.item.status_code == withdrawalStatus.PENDING"
            >
              {{ data.item.status_name }}
            </b-badge>
            <b-badge
              pill
              variant="danger"
              v-if="data.item.status_code == withdrawalStatus.REPROVED"
            >
              {{ data.item.status_name }}
            </b-badge>
            <b-badge
              pill
              variant="success"
              v-if="data.item.status_code == withdrawalStatus.APPROVED"
            >
              {{ data.item.status_name }}
            </b-badge>
            <b-badge
              pill
              variant="primary"
              v-if="
                data.item.status_code == withdrawalStatus.DELETED_BY_REQUESTOR
              "
            >
              {{ data.item.status_name }}
            </b-badge>
          </div>
        </template>
        <!-- Column: Data de alteração -->
        <template #cell(altered_at)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.altered_at
            }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-wrap">
            <b-button
              :id="`withdrawal-details` + data.item.id"
              class="btn-icon mr-1"
              variant="flat"
              style="padding: 0"
              @click="openDetails(data.item.id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-tooltip
              :target="`withdrawal-details` + data.item.id"
              triggers="hover"
            >
              Detalhes da solicitação
            </b-tooltip>
            <b-button
              v-if="data.item.status_code == withdrawalStatus.PENDING"
              :id="`withdrawal-delete` + data.item.id"
              class="btn-icon"
              variant="flat"
              style="padding: 0"
              @click="deleteRequest"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip
              v-if="data.item.status_code == withdrawalStatus.PENDING"
              :target="`withdrawal-delete` + data.item.id"
              triggers="hover"
            >
              Remover
            </b-tooltip>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import WithdrawalRequestCard from "../components/WithdrawalRequestCard";
import WithdrawalDetailsSidebar from "../components/WithdrawalDetailsSidebar";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as withdrawalStatus from "@/constants/withdrawal_status";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,
    vSelect,
    WithdrawalRequestCard,
    WithdrawalDetailsSidebar,
  },
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  data() {
    return {
      withdrawalStatus,
      loading: false,
      tableColumns: [
        { key: "requested_at", label: "Data da solicitação" },
        { key: "amount", label: "Valor solicitado" },
        { key: "status_name", label: "Status" },
        { key: "altered_at", label: "Data de alteração" },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      searchQuery: "",
      eyeOpenBalance: false,
    };
  },
  computed: {
    ...mapGetters({
      withdrawals: types.WITHDRAWALS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.withdrawals?.data || [];
    },
    totalItems: function () {
      return this.withdrawals?.total || 0;
    },
    pageItemsFrom: function () {
      return this.withdrawals?.from || 0;
    },
    pageItemsTo: function () {
      return this.withdrawals?.to || 0;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search();
  },
  methods: {
    ...mapActions({
      getWithdrawals: types.GET_WITHDRAWALS,
      deleteWithdrawal: types.DELETE_WITHDRAWAL,
      openWithdrawalDetailsSidebar: types.OPEN_WITHDRAWAL_DETAILS_SIDEBAR,
    }),
    toSeeBalance() {
      this.eyeOpenBalance = !this.eyeOpenBalance;
    },
    search(currentPage) {
      this.loading = true;
      this.getWithdrawals({
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar os produtos. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDetails(id) {
      this.openWithdrawalDetailsSidebar({ id });
    },
    deleteRequest() {
      this.$swal({
        title: "Tem certeza?",
        text: `Você removerá a solicitação de saque.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary ml-1",
          htmlContainer: `${this.skin === "dark" ? "text-white" : ""}`,
        },
      }).then((result) => {
        if (result.value) {
          this.deleteWithdrawal()
            .then((response) => {
              this.search();
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Sucesso",
                  text: `A solicitação de saque foi removida com sucesso!`,
                  icon: "CoffeeIcon",
                  variant: "success",
                },
              });
            })
            .catch((error) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: "Oops!",
                  text: `Ocorreu um erro ao remover a solicitação de saque. Entre em contato com o setor de TI.`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.glass-effect {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.28);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.mouse-pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
